
import TheSidebar from '@/components/TheSidebar.vue'
import TheAppBar from '@/components/TheAppBar.vue'
import TheAlert from '@/components/TheAlert.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheSupportDialog from '@/components/TheSupportDialog.vue'
import Confetti from '@/components/Confetti.vue'
import TheBookingExperienceSurvey from '@/components/TheBookingExperienceSurvey.vue'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import confetti from '@/store/modules/confetti'
import footer from '@/store/modules/footer'
import support from '@/store/modules/support'
import survey from '@/store/modules/survey'
import { Vue, Component } from 'vue-property-decorator'
import alert from '@/store/modules/alert'
import locale from '../store/modules/locale'
import appMenu from '@/store/modules/appMenu'

@Component({
  components: {
    TheSidebar,
    Confetti,
    TheBookingExperienceSurvey,
    TheAppBar,
    TheAlert,
    TheFooter,
    TheSupportDialog,
  },
  metaInfo() {
    return {
      title: this.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: 'msapplication-TileColor',
          content: '#ffc40d',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.appTitle,
        },
        {
          name: 'application-name',
          content: this.appTitle,
        },
      ],
      link: [
        {
          rel: 'manifest',
          href: '/site.webmanifest',
        },
        {
          rel: 'favicon',
          href: '/favicon.ico',
        },
      ],
    }
  },
})
export default class Home extends Vue {
  confetti = confetti
  auth = auth
  support = support
  survey = survey
  alert = alert
  locale = locale
  appMenu = appMenu

  get appTitle(): string {
    return app.title
  }

  get shouldShowFooter(): boolean {
    return (
      footer.show &&
      this.$route?.name !== 'live-tracking' &&
      this.$route?.name !== 'enterprise-tracking' &&
      this.$route?.params?.mode !== 'confirm'
    )
  }
}
